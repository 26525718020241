











































































































































import Vue from 'vue';
import FormSwitch from '@/components/form/form-switch.vue';
import { CASE_STATUS } from '@/constant/constants';
import popUpModal from '@/components/form/pop-up-modal.vue';
import errorMessage from '@/components/form/error-message.vue';

export default Vue.extend({
  name: 'own-goods',
  components: {
    FormSwitch,
    popUpModal,
    errorMessage,
  },
  props: ['assesment', 'isAPIObserver', 'insuranceData', 'caseStatus', 'AMSAPISendStatus'],
  data() {
    return {
      ownVehicles: 0,
      scope: '',
      maximumInsuredVehicle: 0,
      maximumInsuredVehicleError: false,
      maximumInsuredVehicleZeroError: false,
      ...this.insuranceData,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      componentLoaded: false,
      scopeSelected: true,
      ownVehiclesSelected: true,
    };
  },
  watch: {
    maximumInsuredVehicle(val) {
      if (val > 100000) {
        this.maximumInsuredVehicleError = true;
      } else {
        this.maximumInsuredVehicleError = false;
      }
    },
    changeData: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    firstPageDataChange() {
      this.$emit(
        'first-page-data-changed',
      );
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    onSubmit() {
      if (this.ownVehicles === 0) {
        this.ownVehiclesSelected = false;
        setTimeout(() => this.scrollToFirstErrorElement(), 500);
        return;
      }
      if (this.scope === '') {
        this.scopeSelected = false;
        return;
      }
      if (this.maximumInsuredVehicle === 0) {
        this.maximumInsuredVehicleZeroError = true;
        setTimeout(() => this.scrollToFirstErrorElement(), 500);
      } else {
        this.maximumInsuredVehicleZeroError = false;
        const data = {
          ownVehicles: this.ownVehicles,
          scope: this.scope,
          // Ausstellungen: false,
          maximumInsuredVehicle: this.maximumInsuredVehicle,
        };

        const priceData = {
          AnzahlFahrzeuge: this.ownVehicles,
          Geltungsbereich: this.scope,
          // Ausstellungen: false,
          BetriebsartWerkverkehr: this.assesment.operatingMode.BART_TEXT,
          MaxVs: this.maximumInsuredVehicle,
        };
        this.$emit('data-changed', '77d9bf3b-d98f-4a88-8aa7-cf35ddd2c3cc', priceData, data);
      }
    },
    setValues(insuranceData: any): void {
      if (insuranceData.ownVehicles) {
        this.ownVehicles = insuranceData.ownVehicles;
      }
      if (insuranceData.scope) {
        this.scope = insuranceData.scope;
      }
      if (insuranceData.maximumInsuredVehicle) {
        this.maximumInsuredVehicle = insuranceData.maximumInsuredVehicle;
      }
      // this.assesment.operatingMode.BART_TEXT = insuranceData.BART_TEXT;
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    scrollToFirstErrorElement() {
      const el = this.$el.getElementsByClassName('red-border')[0];
      if (el) {
        el.scrollIntoView();
      }
    },
  },
  mounted() {
    if (!this.assesment) {
      this.$router.replace('/transport-insurance');
    }
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }

    if (this.isDisableAll) {
      this.disableAllInputs();
    }
  },
  computed: {
    changeData(): any {
      return [this.ownVehicles, this.scope, this.maximumInsuredVehicle].join();
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
  },
  beforeUpdate() {
    this.componentLoaded = true;
  },
});
